import React from "react"
import theme from "theme"
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"contacts"} />
      <Helmet>
        <title>Гольф-клуб Tróia</title>
        <meta
          name={"description"}
          content={"Де кожен удар розповідає історію"}
        />
        <meta property={"og:title"} content={"Гольф-клуб Tróia"} />
        <meta
          property={"og:description"}
          content={"Де кожен удар розповідає історію"}
        />
        <meta
          property={"og:image"}
          content={"https://amirevox.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://amirevox.com/img/001-icon-golf.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://amirevox.com/img/001-icon-golf.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://amirevox.com/img/001-icon-golf.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://amirevox.com/img/001-icon-golf.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://amirevox.com/img/001-icon-golf.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://amirevox.com/img/001-icon-golf.png"}
        />
      </Helmet>
      <Components.Header />
      <Section background="--color-primary" padding="80px 0 80px 0">
        <Box
          display="flex"
          align-items="center"
          flex-direction="column"
          justify-content="center"
          padding="0px 100px 0px 100px"
          lg-padding="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 30px 0px"
            font="--headline1"
            color="--light"
            text-align="center"
            sm-font='normal 700 62px/1.2 "Source Sans Pro", sans-serif'
          >
            Контакти
          </Text>
          <Text
            margin="0px 0px 48px 0px"
            font="--lead"
            color="--light"
            text-align="center"
          >
            Pronto para experimentar a magia de Tróia? Будемо на зв'язку
            connosco e vamos começar a sua viagem.
          </Text>
          <Link
            href="mailto:blank?admin@amirevox.com"
            text-align="center"
            color="--light"
            font="--headline3"
            text-decoration-line="initial"
            margin="0px 0px 16px 0px"
          >
            admin@amirevox.com
          </Link>
          <Link
            href="tel:+351 265 494 024"
            text-align="center"
            color="--light"
            font="--headline3"
            text-decoration-line="initial"
            margin="0px 0px 16px 0px"
          >
            +351 265 494 024
          </Link>
          <Text
            margin="0px 0px 48px 0px"
            font="--headline3"
            color="--light"
            text-align="center"
          >
            Troia Golfe, Tróia, 7570-789 Troia, Portugal
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
